.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f1f1f1;
  }
  
  .login-form {
    width: 30%;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .login-form h1 {
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .login-form input {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .login-form button {
    width: 100%;
    padding: 10px;
    border: 0;
    border-radius: 5px;
    background-color: #0072c6;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .login-form button:hover {
    background-color: #005a9e;
  }
  