.mainDiv {
  width: 100vw;
  overflow: hidden;
  border: 0px solid rgb(5, 221, 77);
  padding: 2px;
  height: 100%;
}
.leftDiv {
  border: 0px solid red;
  background: #0e193a;
  padding: 0;
}

@media only screen and (max-width: 768px) {
  .leftDiv {
    width: 100vw;
    background: #0e193a;
    padding: 0;
  }
}

.rightDiv {
  top: 0px;
  left: 440px;
  position: fixed;
  float: left;
  border: 0px solid rgb(70, 5, 221);
  width: 80vw;
  height: 97vh;
  background-image: url(../../img/backFront.jpg);
}
.logo {
  /* width: 123px; */
  height: 5vh;
  margin: 5px 5px;
  cursor: pointer;
}

.fasearch {
  color: white;
  cursor: pointer;
}
.rightTopDiv {
  height: 50px;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: "Roboto";
  font-style: normal;
  border: 0px solid rgb(70, 5, 221);
  display: flex;
  padding-left: 10px;
  padding-top: 10px;
}
.welcomeTextDiv {
  height: 320px;
  width: 350px;
  /* position: absolute; */
  top: 190px;
  left: 200px;
  font-family: "Roboto";
  font-style: normal;
}

.front1 {
  height: 30vh;
  bottom: 0;
  left: 0;
  position: absolute;
}
.front2 {
  height: 40vh;
  position: absolute;
  left: 9em;
  bottom: 8em;
}

.front1-mobile {
  width: 50vw;
  top: 0;
  left: 0;
  position: absolute;
}
.front2-mobile {
  width: 50vw;
  position: absolute;
  left: 40vw;
  top: -1vh;
}

.backFront {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}

.errorMessage {
  color: #dc3545;
  font-size: 16px;
  padding: 15px;
}

.mainContent {
  background-image: url(../../img/backFront.jpg);
  min-height: 100vh;
  /* vertical-align: middle; */
}

.welcome-images {
  position: relative;
  height: 60vh;
}

.admin-button {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}

.admin-button .icon {
  margin-right: 0.5rem;
  font-size: 1.5rem;
  vertical-align: top;
}

.signout-button .icon {
  margin-right: 0.5rem;
  font-size: 1.5rem;
  vertical-align: top;
}

div.mobile-sign-out-button .signout-button {
  background-color: #ffffff;
  color: #0e193a;
  padding: 5px 0px 5px 0px;
}

.create-workorder-button {
  background-color: #fd7e14 !important;
}

.show-workorders-button {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.create-workorder-button .icon {
  margin-right: 0.5rem;
  font-size: 1.5rem;
  vertical-align: top;
}

.menu-divider {
  font-size: 1.8em;
  opacity: 0.4;
}

.separator_line {
  width: 0px;
  height: 4vh;
  margin-left: 1vw;
  margin-right: 2vw;
  border: 1px solid #ffffff;
  display: inline-flex;
  vertical-align: middle;
  /* position: relative; */
  /* display: inline; */
  /* float: left; */
}

.welcome-images-mobile {
  height: 25vh;
}

@media only screen and (max-width: 1024px) and (min-width: 500px) {
  .welcome-images-mobile {
    height: 45vh;
  }
}