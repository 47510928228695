.button-primary-button-primary {
  width: 200px;
  height: auto;
  display: flex;
  padding: 14px 10px;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-color: transparent;
  border-radius: 40px;
  justify-content: center;
  background-color: #fd7e14;
  color: transparent;
  cursor: pointer;
  margin-right: 10px;
}

/* .pos1 {
  top: 720px;
  left: 550px;
  top: 75vh;
  left: 5vw;
} */

.button-primary-text {
  color: #ffffff;
  height: auto;
  align-self: auto;
  text-align: center;
  line-height: 18px;
  margin-right: 0;
  margin-bottom: 0;
}
