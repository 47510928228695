.txtBox {
    padding: 0;
    height: 30px;
    position: relative;
    left: 0;
    outline: none;
    border-right: 0px;
    border-top: 0px;
    border-left: 0px;
    border-bottom: 2px solid white;
    background-color: #0E193A;
    font-size: 16px;
    color: white;
    margin-left: 5px;
    padding-left: 5px;
    margin-bottom: 10px;
    width: 80%;
  }

  @media only screen and (max-width: 768px) {
    .txtBox {
      height: 45px;
      font-size: 18px;
      width: 90%;
    }
  }