.divExtWO {
  border-radius: 10px;
  z-index: 9;
  position: absolute;
  left: 45%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 0px solid #262c3073;
  background-color: rgb(255, 255, 255);
  height: 720px;
  width: 780px;
}

.modalDialog {
  border-radius: 10px;
  height: 100dvh;
  width: 100vw;
}

.divExtHeader {
  width: 100%;
  height: 67px;
  background-color: #013d7d;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.labelExtStep {
  float: left;
  align-items: center;
  letter-spacing: 0.1em;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: white;
  margin-top: 25px;
  margin-left: 10px;
}

.spanExtStep {
  float: right;
  align-items: center;
  letter-spacing: 0.1em;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: white;
  margin-top: 25px;
  margin-right: 10px;
}

.divExtMiddle {
  border: 0px solid #262c3073;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
}

.tabCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.divExtFooter {
  /*position: fixed;*/
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.modualFooter {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.tabelChapter {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #0e193a;
  padding: 0px 18px;
}

.tabelExt td {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #0e193a;
  padding: 0px 20px;
}

.tabelExt th {
  border: 0px solid #262c3073;
  text-align: left;
}

.loadgif {
  margin-top: 100px;
}

.divWorkDescLo {
  max-height: 480px;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: pre-wrap;
  width: 100%;
  word-wrap: break-word !important;
}

.divWorkDescLoEdit {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: pre-wrap;
  width: 100%;
  word-wrap: break-word !important;
}
@media only screen and (min-width: 768px) {
  .table1 {
    width: 700px;
    margin-right: 12em;
  }

  .modalpc {
    min-height: 600px;
  }
}
.btnUpd {
  margin-top: 20px;
}

.faEdit {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.divWoCanceled {
  border-radius: 10px;
  z-index: 9;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #262c3073;
  background-color: rgb(226, 219, 219);
  height: 180px;
  width: 200px;
  border-radius: 20px;
  opacity: 0.9;
  text-align: center;
}

.divFileContainer {
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid #92969973;
}

.divFileName {
  float: left;
  border: 0px solid #262c3073;
  min-width: 27em;
  margin-top: 0.6em;
}
.divFileButton {
  display: inline;
  border: 0px solid #262c3073;
}

.divFile {
  max-height: 30em;
  overflow: auto;
}

.statusCircle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  margin-right: 3px;
  margin-top: 3px;
}

.divSpinnerCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.div-container-attachment {
  margin-top: 20px;
}

.div-add-attachment {
  margin-top: 20px;
}

.div-list-attachment {
  margin-top: 20px;
  max-height: 300px;
  overflow-y: auto;
}
