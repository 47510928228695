.divNewWO {
  border-radius: 10px;
  z-index: 9;
  /*position: absolute;
  left: 45%;
  top: 40%;*/
  transform: translate(-50%, -50%);
  border: 0px solid #262c3073;
  background-color: rgb(255, 255, 255);
  height: 520px;
  width: 780px;
}

.divNewWOCreated {
  border-radius: 10px;
  z-index: 9;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #262c3073;
  background-color: rgb(226, 219, 219);
  min-height: 180px;
  width: 200px;
  border-radius: 20px;
  opacity: 0.9;
  text-align: center;
  padding-bottom: 10px;
}

.loadGif {
  width: 50px;
  height: 50px;
}

.loadGifsmall {
  width: 25px;
  height: 25px;
}

.loadGiflist {
  align-self: center;
}

.divHeader {
  width: 100%;
  height: 67px;
  background-color: #013d7d;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.modal-header {
  display: block;
  line-height: 0;
  margin: 0;
  padding: 0;
}

@media only screen and (min-width: 768px) {
  .tableCreate {
    width: 700px;
    margin-right: 12em;
  }
  TextArea {
    width: 500px;
    min-width: 500px;
  }
}

.header_div {
  position: relative;
  height: 100%;
  width: 100%;
}

.divBar {
  width: 100%;
  height: 3px;
  border: 0px solid #9c284173;
  position: absolute;
  bottom: 0px;
}

.divBarPart {
  float: left;
  width: 12.5%;
  height: 100%;
}

.divBarPartColor {
  background-color: #fd7e14;
}

.divMiddle {
  width: 100%;
  border: 0px solid #262c3073;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  max-height: 380px;
}

.divFooter {
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.labelStep {
  float: right;
  align-items: center;
  text-align: right;
  letter-spacing: 0.1em;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: white;
  margin-top: 25px;
  margin-right: 10px;
}

td {
  padding-right: 10px;
  border: #fd7e14 0px solid;
}

tr {
  border: #0f8a34 0px solid;
}

.btn {
  display: inline-block;
  color: #ffffff;
  background-color: #0e193a;
  border: none;
  padding: 5px 20px;
  margin: 5px;
  border-radius: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.btn:hover {
  background-color: #28304d;
  color: #d4d5e9;
}

.btn:disabled {
  background-color: #1d243b;
  color: #ffffff;
  cursor: default;
  opacity: 0.6;
}

.btnDisabeld {
  opacity: 0.5;
  cursor: default;
}

.btnNotVisible {
  display: none;
  cursor: default;
}

.btnNext {
  float: right;
  margin-right: 0.2rem;
  margin-bottom: 1rem;
}

.btnEnd {
  float: left;
  margin-left: 1vw;
  margin-bottom: 20px;
}

.stepH3 {
  margin-bottom: 7px;
  font-family: "Roboto";
  font-style: normal;
  font-size: 20px;
}

.infoCircelTop {
  width: 14px;
}

.infoCircelLeft {
  width: 14px;
  color: #ffffff;
}

.infoCircelTop2 {
  font-size: 15px !important;
  font-weight: 100 !important;
  white-space: pre-wrap;
  z-index: 10;
}

.main-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.description-container {
  flex: 1;
  margin-top: 140px;
  padding: 5px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
}

#description-text {
  font-size: 14px;
  color: #555;
}

.div-table {
  display: table;
  width: auto;
  background-color: #ffffff;
  border: 0px solid #666666;
  border-spacing: 5px;
}
.div-table-row {
  display: table-row;
  width: auto;
  clear: both;
}
.div-table-col {
  float: left;
  display: table-column;
  width: 260px;
  background-color: #ffffff;
}
.div-table-col-small {
  float: left;
  display: table-column;
  background-color: #ffffff;
  min-height: 50px;
}
.div-table-col-name {
  width: 300px;
  margin-top: 10px;
}

.div-table-col-date {
  width: 150px;
  margin-top: 10px;
}

.div-table-col-header {
  float: left;
  display: table-column;
  width: 100%;
  background-color: #ffffff;
}
.modalDialogNewWo {
  border-radius: 10px;
  height: 100dvh;
  width: 100vw;
}
.modalDialogNewWoOpenWoOpen {
  opacity: 0;
}

.table-save {
  display: flex;
  flex-wrap: wrap;
}

.row-save {
  width: 100%;
  display: flex;
}

.cell-save {
  width: 25%;
  padding: 5px;
  box-sizing: border-box;
}

.h3savedWo {
  font-size: 18px;
  line-height: 17px;
  color: white;
  margin-top: 25px;
  margin-left: 10px;
  letter-spacing: 0.1em;
}

.progressBar {
  height: 4px;
}
.bg-progressBar {
  background-color: #fd7e14 !important;
}
