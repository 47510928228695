.dd {
  display: inline-block;
  background: rgb(216, 217, 219);
  color: rgb(47, 57, 73);
  border: none;
  padding: 5px 20px;
  margin: 5px 5px -3px 5px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
  width: 235px;
}

.txtH6dd {
  margin-bottom: -1px;
  margin-left: 6px;
  margin-top: 1px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  color: #0e193a;
}

.errSmalldd {
  margin-bottom: 0px;
  margin-left: 6px;
  margin-top: -17px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 20px;
  color: #e21a1a;
  height: 12px;
}

.reactSelect {
  margin-bottom: -25px;
  z-index: 1;
  margin-left: 5px;
}

.reactSelectObject {
  z-index: 5;
}

@media only screen and (max-width: 768px) {
  .react-tooltip {
    max-width: 70vw;
  }
}
