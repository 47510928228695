.h3ShortDesc {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #fd7e14;
  margin-top: -1px;
  margin-bottom: -4px;
  margin-left: 5px;
  cursor: pointer;
}

.labelList {
  float: left;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 22px;
  color: #ffffff;
}

.divTable {
  height: 100%;
  max-height: 80vh;
  border: 0px solid #e71414;
  border-spacing: 5px;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fd7e14;
  border-radius: 6px;
}

.divList {
  border: 0px solid rgb(70, 5, 221);
  display: table-row;
}

.divItem {
  display: table-row;
}

.divAddress {
  display: table-row;
  margin-top: -6px;
  margin-bottom: 4px;
  margin-left: 5px;
  color: #ffffff;
  float: left;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 22px;
}

.color1 {
  color: #fff500 !important;
}

.color2 {
  color: #b8ff71 !important;
}

.color3 {
  color: #14f1ff !important;
}

.color4 {
  color: #27ff36 !important;
}

.color5 {
  color: #00e13f !important;
}

.color6 {
  color: #ff6464 !important;
}

.color7 {
  color: #ff0000 !important;
}
.color1b {
  background-color: #fff500 !important;
}

.color2b {
  background-color: #b8ff71 !important;
}

.color3b {
  background-color: #14f1ff !important;
}

.color4b {
  background-color: #27ff36 !important;
}

.color5b {
  background-color: #00e13f !important;
}

.color6b {
  background-color: #ff6464 !important;
}

.color7b {
  background-color: #ff0000 !important;
}
