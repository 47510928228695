.txtBox2 {
  display: inline-block;
  background: rgb(216, 217, 219);
  color: rgb(36, 45, 59);
  border: none;
  padding: 5px 20px;
  margin: 5px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
  width: 200px;
  margin-bottom: -4px;
}

.txtArea {
  display: inline-block;
  background: rgb(216, 217, 219);
  color: rgb(36, 45, 59);
  border: none;
  padding: 5px 20px;
  margin: 5px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
  width: 100%;
}

.textAreaCounter {
  margin-bottom: 5px;
  margin-left: 10px;
  margin-top: -9px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: rgb(36, 45, 59);
}

.textAreaCounterMax {
  color: #e21a1a;
}

.txtBox2_ORG {
  display: inline-block;
  background: rgb(240, 241, 243);
  color: rgb(47, 57, 73);
  border: none;
  padding: 5px 20px;
  margin: 5px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.txtBox2_small {
  width: 40px;
}

@media only screen and (min-width: 768px) {
  .txtBox2_big {
    width: 500px;
  }
}

.txtBox2_disabled {
  color: rgb(200, 203, 206);
  background: rgb(246, 247, 248);
  border: 2px;
}

.txtBox2_error {
  border: #f02626 1px solid !important;
}

.txtH6 {
  margin-bottom: -6px;
  margin-left: 6px;
  margin-top: 1px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  color: #0e193a;
}

.errSmall {
  margin-bottom: 0px;
  margin-left: 6px;
  margin-top: -7px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 20px;
  color: #e21a1a;
  height: 12px;
}
