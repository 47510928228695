.divMainWindow {
  border-radius: 10px;
  z-index: 9;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 0px solid #262c3073;
  background-color: rgb(255, 255, 255);
  height: 720px;
  width: 780px;
}
.divMainHeader {
  width: 100%;
  height: 67px;
  background-color: #013d7d;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.divMainMiddle {
  width: 100%;
  border: 0px solid #262c3073;
  display: flex;
  justify-content: center;
}
.labelMainStep {
  float: left;
  align-items: center;
  letter-spacing: 0.1em;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: white;
  margin-top: 25px;
  margin-left: 10px;
}

.divMainFooter {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.tdRight {
  text-align: right;
}

.tdSpace {
  padding-top: 30px;
}

.divTab {
  width: 650px;
  border: 0px solid #262c3073;
}

.divUser {
  border: 0px solid #262c3073;
}

.divUsers {
  overflow-y: scroll;
  max-height: 460px;
}

.pUserName {
  font-family: "Roboto";
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.1em;
  margin-top: -3px;
  margin-bottom: -2px;
}

.pLastLoggedIn {
  font-family: "Roboto";
  font-weight: 100;
  font-size: 12px;
  letter-spacing: 0.1em;
}

.divUserName {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.1em;
  margin: auto;
}

.divHide {
  text-indent: 100%;
  font-size: 1px;
  white-space: nowrap;
  overflow: hidden;
}

.txtAdmin {
  display: inline-block;
  background: rgb(216, 217, 219);
  color: rgb(36, 45, 59);
  border: none;
  padding: 5px 20px;
  margin: 5px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
  width: 300px;
}

.txtUserSetting {
  display: inline-block;
  background: rgb(216, 217, 219);
  color: rgb(36, 45, 59);
  border: none;
  padding: 5px 20px;
  margin: 5px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
  width: 100px;
}

.imgTrash {
  width: 15px;
  height: 20px;
  margin-left: 5px;
  padding-top: 5px;
  color: rgb(230, 115, 115);
  cursor: pointer;
}
.imgAdd {
  width: 15px;
  height: 20px;
  margin-left: 5px;
  padding-top: 5px;
  color: rgb(104, 230, 132);
  cursor: pointer;
}

.divLine {
  color: rgb(230, 115, 115);
  width: 95%;
  border: 1px solid #262c3073;
  margin-top: 7px;
  margin-bottom: 7px;
}

.tableInvite {
  width: 90%;
  margin-right: 12em;
}
