.checkboxLabel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  color: #0e193a;
  margin-top: 103px;
  vertical-align: middle;
  bottom: 1px;
  position: relative;
}

.checkbox-wrapper {
  margin-top: 10px;
  margin-left: 6px;
}

.checkbox-info-circle {
  vertical-align: text-top;
  width: 0.9rem;
  height: 0.9rem;
}
