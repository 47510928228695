.radioLabel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  color: #0e193a;
  margin-top: 7px;
}

.divRadio {
  margin-top: 0px;
  margin-bottom: -1px;
  margin-left: 6px;
}
