.txtH6extWo {
  margin-bottom: -6px;
  margin-left: 0px;
  margin-top: 15px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #0e193a;
  opacity: 0.6;
}

.txtBoxComp {
  display: inline-block;
  background: rgb(216, 217, 219);
  color: rgb(36, 45, 59);
  border: none;
  padding: 5px 20px;
  margin-top: 5px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
  width: 180px;
}
@media only screen and (max-width: 768px) {
  .txtBoxComp {
    width: 100%;
  }
}

.divText {
  display: flex;
  margin-top: 3px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
  color: #0e193a;
}

.txtAreaExisting {
  display: inline-block;
  background: rgb(216, 217, 219);
  color: rgb(36, 45, 59);
  border: none;
  padding: 5px 20px;
  margin: 5px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
  width: 95%;
}
