.react-select-search-left {
    border-bottom: 2px solid white;
    width: 80%;
    display: inline-block;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 600px) {
    .react-select-search-left {
      width: 90%;
    }
  }